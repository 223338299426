<template lang="pug">
.mediaKit
  nav-bar
  .background
  img.background(src='@/assets/images/mediaKit/background.png')
  .media_box
    .head_box(v-html="$TT(head)")
    .product_panel
      .title_box
        .text {{$TT('Product Description')}}
        .download(
          @click="showOptions"
          :style="isShow ? 'border-radius: 10px 10px 0 0' : 'border-radius: 10px'"
        )
          span.content {{$TT('PDF Download')}}
          img.arrow(:src="downloadIcon")
        .options(v-show="isShow")
          pdf-download(
            v-for="(item, index) in dowloadData"
            :key="index"
            :iconData="item"
          )
      .gap
      pdf-display(:pdfData="display")

  contact-footer.footer
  dict-footer
</template>
<script>
import data from '@/config/PDFDownload'
import navBar from '@/components/pc/navBar.vue'
import contactFooter from '@/components/pc/contactFooter.vue'
import dictFooter from '@/components/pc/dictFooter.vue'
import pdfDownload from './components/pdfDownload.vue'
import pdfDisplay from './components/pdfDisplay.vue'

export default {
  name: 'MediaKit',
  components: {
    navBar,
    contactFooter,
    dictFooter,
    pdfDownload,
    pdfDisplay,
  },
  data() {
    return {
      isShow: false,
      downloadIcon: require('@/assets/images/mediaKit/download_arrow-down.png'),
      dowloadData: {},
      display: [],
      // 国际化需求
      head: "<span class='text'>U-Dictionary</span><span class='bold'>Media Kit</span>",
    }
  },
  mounted() {
    this.dowloadData = data.pdf;
    this.display = data.pdfImg[this.$route.params.lang]
    this.log('ud_web_download_button_view')
  },
  watch: {
    isShow: {
      handler(val) {
        if (!val) {
          this.downloadIcon = require('@/assets/images/mediaKit/download_arrow-down.png');
        } else {
          this.downloadIcon = require('@/assets/images/mediaKit/download_arrow-up.png')
        }
      },
    },
    '$store.state.selectLang': {
      handler(val) {
        this.display = data.pdfImg[val];
      },
    },
  },
  methods: {
    showOptions() {
      this.isShow = !this.isShow;
    },
  },
}
</script>
<style lang="stylus" scoped>
.mediaKit
  position relative
  font-family Gilroy
  width 100%
  min-width 1300px
  .media_box
    position relative
    width 1300px
    padding-top 62px
    margin 0 auto
    .head_box
      width 500px
      margin 166px auto
      font-size 32px
      line-height 37px
      color #FFF
      text-align center
      .text
        font-weight 600px
      .bold
        font-weight bold
        margin-left 10px
    .product_panel
      position relative
      width 1120px
      margin -50px auto
      border-radius 20px
      border 1px solid #CACACA
      z-index 100
      background #fff
      .title_box
        background #fff
        display flex
        height 92px
        border-radius 20px 20px 0 0
        .text
          width 400px
          font-weight 600
          font-size 20px
          line-height 24px
          color #061c32
          margin 36px 0 0 76px
        .download
          width 160px
          height 40px
          margin 28px 0 0 442px
          border-radius 10px 10px 0 0
          background #FF592B
          border-bottom 1px solid rgba(255,255,255,0.2)
          .content
            font-family PingFang SC
            color #FFF
            font-size 14px
            line-height 40px
            margin-left 20px
          .arrow
            width 10px
            margin 0 0 2px 10px
          &:hover
            cursor pointer
        .options
          position absolute
          right 42px
          top 69px
          width 160px
          background #FF592B
          border-radius 0 0 10px 10px
      .gap
        width 100%
        height 24px
        background rgba(202,202,202,1)
      .product_pdf
        color red
  .footer
    margin-top 124px
 .background
    position absolute
    height 527px
    width 100%
</style>
