<!--
* 用于将pdf按照图片的形式在页面展示
-->
<template lang="pug">
.pdf-display
  .page
    img.image(v-for="(link, index) in displayData" :src="link")
</template>
<script>
export default {
  name: 'pdf-display',
  props: ['pdfData'],
  data() {
    return {
      displayData: [],
    }
  },
  watch: {
    pdfData: {
      handler(val) {
        this.displayData = val;
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.pdf-display
  width 1120px
  height 1200px
  border-radius 0 0 20px 20px
  overflow auto
  .image
    width 100%
</style>
