/*
* 用于 media kit 页面上 PDF download下载按钮下不同语言* 所对应的 pdf 的相关信息
*/

const pdf = {
  English: {
    name: 'English',
    // 上传至YouDao-Luna-NOS。
    link: 'https://ydlunacommon-cdn.nosdn.127.net/3b25a593d8d642d0f4f21ac394f711c8.pdf',
  },
  Spanish: {
    name: 'Español',
    link: 'https://ydlunacommon-cdn.nosdn.127.net/e89d4129f1b7960287ae587c51a09214.pdf',
  },
  Bahasa: {
    name: 'Bahasa',
    link: 'https://ydlunacommon-cdn.nosdn.127.net/74c084e3b095643b4298ace23d97ef85.pdf',
  },
  Arabic: {
    name: 'العربية',
    link: 'https://ydlunacommon-cdn.nosdn.127.net/28bd975a58590e4db8c8d68442ab5dbe.pdf',
  },
};
const pdfImg = {
  English: [
    require('@/assets/images/mediaKit/English/English-01.jpg'),
    require('@/assets/images/mediaKit/English/English-02.jpg'),
    require('@/assets/images/mediaKit/English/English-03.jpg'),
    require('@/assets/images/mediaKit/English/English-04.jpg'),
    require('@/assets/images/mediaKit/English/English-05.jpg'),
    require('@/assets/images/mediaKit/English/English-06.jpg'),
    require('@/assets/images/mediaKit/English/English-07.jpg'),
    require('@/assets/images/mediaKit/English/English-08.jpg'),
    require('@/assets/images/mediaKit/English/English-09.jpg'),
    require('@/assets/images/mediaKit/English/English-10.jpg'),
    require('@/assets/images/mediaKit/English/English-11.jpg'),
    require('@/assets/images/mediaKit/English/English-12.jpg'),
  ],
  Español: [
    require('@/assets/images/mediaKit/Span/Span-01.jpg'),
    require('@/assets/images/mediaKit/Span/Span-02.jpg'),
    require('@/assets/images/mediaKit/Span/Span-03.jpg'),
    require('@/assets/images/mediaKit/Span/Span-04.jpg'),
    require('@/assets/images/mediaKit/Span/Span-05.jpg'),
    require('@/assets/images/mediaKit/Span/Span-06.jpg'),
    require('@/assets/images/mediaKit/Span/Span-07.jpg'),
    require('@/assets/images/mediaKit/Span/Span-08.jpg'),
    require('@/assets/images/mediaKit/Span/Span-09.jpg'),
    require('@/assets/images/mediaKit/Span/Span-10.jpg'),
    require('@/assets/images/mediaKit/Span/Span-11.jpg'),
    require('@/assets/images/mediaKit/Span/Span-12.jpg'),
  ],
  Bahasa: [
    require('@/assets/images/mediaKit/Indonesia/Indonesia-01.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-02.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-03.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-04.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-05.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-06.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-07.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-08.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-09.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-10.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-11.jpg'),
    require('@/assets/images/mediaKit/Indonesia/Indonesia-12.jpg'),
  ],
  العربية: [
    require('@/assets/images/mediaKit/Arabic/Arabic-01.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-02.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-03.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-04.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-05.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-06.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-07.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-08.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-09.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-10.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-11.jpg'),
    require('@/assets/images/mediaKit/Arabic/Arabic-12.jpg'),
  ],
}
export default {
  pdf,
  pdfImg,
}
