<!--
* pdf-download: media-kit组件
* 用途：用于展示所有语言的 pdf 下载图标。
-->
<template lang="pug">
.download_icon
  a.box(:href="iconData.link" download)
    .lang {{ iconData.name }}
    img.icon(src='@/assets/images/mediaKit/download_icon.png')
</template>
<script>
export default {
  name: 'pdfDownload',
  props: ['iconData'],
}
</script>
<style lang="stylus" scoped>
.download_icon
  position relative
  height 40px
  width 100%
  border-bottom 1px solid rgba(255,255,255,0.2)
  .box
    margin 9px 0 9px 20px
    .lang
      position absolute
      top 10px
      left 20px
      font-family PingFang SC
      font-size 14px
      color #FFF
    .icon
      position absolute
      width 12px
      top 12px
      right 20px

</style>
